<template>
    <el-form ref="ruleFormRef" :label-width="props.labelWidth+'px'" :label-position="props.labelPosition" :model="formModel" class="demo_ruleForm">
        <!-- 传入自定义规则时也改为添加红星 -->
        <el-form-item v-for="(el, ind) in props.FormDatas" :label="el.label" :prop="el.key" :rules="el.rules" class="pt-r ml-2" :class="[el.rules.length>0?'is-required':'']">
            <!-- key值为验证码时展示验证码发送按钮 -->
            <verificationCode class="pt-a vcode" v-if="el.key == 'token'" @Emit="vCodeEmit" ref="verificationCodeRef"></verificationCode>
            <el-input v-model="formModel[el.key]" :placeholder="'请输入' + el.label.slice(0, -1)" :disabled="el.disabled" maxlength="6" v-if="el.key == 'token'" />
            <!-- type 1为输入框  2 为下拉框·-->
            <el-input v-model="formModel[el.key]" :placeholder="'请输入' + el.label.slice(0, -1)" :disabled="el.disabled" v-else-if="el.type == 1" />
            <el-select v-model="formModel[el.key]" class="m-2" :placeholder="'请选择' + el.label.slice(0, -1)"
                v-else-if="el.type == 2" @change="selectChange(formModel[el.key], el.key)" :disabled="el.disabled">
                <el-option v-for="item in el.options" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            <!-- 两位小数 -->
            <el-input v-model="formModel[el.key]" :placeholder="'请输入' + el.label.slice(0, -1)" :disabled="el.disabled" v-else-if="el.type == 3" @input="formModel[el.key] = formModel[el.key].replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g,'$1')" />
            <!-- 仅数字 -->
            <el-input v-model="formModel[el.key]" :placeholder="'请输入' + el.label.slice(0, -1)" :disabled="el.disabled" v-else-if="el.type == 4" @input="formModel[el.key] = formModel[el.key].replace(/^\D*(\d*(?:)?).*$/g,'$1')" />
        </el-form-item>
    </el-form>
</template>
<script setup>
import { reactive, ref, unref, watch, defineEmits,nextTick } from "vue";
import { handleMessage } from "@/utils/server/confirm.js";
import { httpToken } from "@/utils/request";
import { validPhone } from "@/utils/server/validate.js";
import qs from 'qs'
import verificationCode from '@/components/button/verificationCode.vue'//验证码组件
const verificationCodeRef = ref()//验证码组件ref
const props = defineProps({
    // 表单域标签的位置
    labelPosition:{
        type: String,
        default: () => {
            return 'left'
        }
    },
    // 标题长度
    labelWidth:{
        type: Number,
        default: () => {
            return 200
        }
    },
    FormDatas: {
        type: Array,
        default: () => {
            return [
                { label: '个体户商户名称：', type: 1, key: 'str1', default: '', rules: [{ required: true, message: '请填写个体户商户名称' }] },
                { label: '统一社会信用代码或营业执照注册号：', type: 1, key: 'str2', default: '', rules: [{ required: true, message: '请填写统一社会信用代码或营业执照注册号' }] },
                { label: '经营者姓名：', type: 1, key: 'str3', default: '', rules: [{ required: true, message: '请填写经营者姓名' }] },
            ]
        }
    },
});
const emit = defineEmits(['authenticationTypeEmit', 'submit'])
const ruleFormRef = ref(null);//表单ref
const state = reactive({
});
const formModel = reactive({})//数据绑定值
// 表单提交 触发整个表单校验
const submitForm = async () => {
    const form = unref(ruleFormRef)
    if (!form) return
    await form.validate((valid) => {
        if (valid) {
            emit('submit', true)
        } else {
            emit('submit', false)
        }
    })
}
watch(
  () => props.FormDatas,
  (value, oldValue) => {
    props.FormDatas.forEach(item => {
        formModel[item.key] = item.default
    })
  },{
    deep: true,
    immediate: true,
  }
  );
props.FormDatas.forEach(item => {
    formModel[item.key] = item.default
})
// 验证码返回
const vCodeEmit = (() => {
    if(formModel.phone==''){
        handleMessage('请输入手机号')
        return
    }
    if (!validPhone(formModel.phone)) {
        handleMessage('手机号格式错误!')
        return
    }
    httpToken({
        method: "post",
        url: '/admin/app/sms',
        data: {
            'phone': formModel.phone,
            'exist': false,
            'appid': '1',
            'type': 5,
        }
    }).then(res => {
        handleMessage("验证码发送成功", "success")
        unref(verificationCodeRef)[0].send()
    })
})
const selectChange = ((val, key) => {
    if (key == 'authenticationType') {
        emit('authenticationTypeEmit', val)
    }
    nextTick(()=>{
      unref(ruleFormRef).clearValidate()//清除表单验证结果
    })
})
defineExpose({
  submitForm,
  formModel
});
</script>
<style lang="scss" scoped >
.demo_ruleForm {
    ::v-deep .el-form-item__label {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333;
        height: 40px;
        line-height: 40px;
    }

    ::v-deep .el-form-item {
        margin-bottom: 22px;
    }

    ::v-deep .el-form-item__content {
        width: 220px;
        flex-shrink: 0;
        flex-grow: 0;
        flex-basis: auto;
        margin-left: 2px;
    }
    ::v-deep .el-input{
        height: 46px;
    }
}

.vcode {
    z-index: 10;
    right: 18px;
}
</style>